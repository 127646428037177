@media only screen and (max-width: 800px) {
  html {
    font-size: 50%;
  }

  .nav-resp {
    display: block;
  }

  .links__container {
    display: none;
  }

  .nav__links {
    display: block;
    list-style: none;
  }

  .nav__link:link,
  .nav__link:visited {
    color: var(--color-primary);
  }

  .nav-bar {
    padding: 0 1rem;
  }

  .section__links {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .section__link {
    width: 80%;
  }

  .section__options-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .locations-container {
    width: 95%;
  }

  .benefits {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .requirements-container {
    width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  h2 {
    font-size: 2.5rem;
    line-height: 140%;
  }

  .hero__info-header {
    display: block;
    gap: 3rem;
  }

  .hero__info-img {
    width: 15rem;
  }

  .hero__info-title {
    font-size: 8rem;
  }

  .hero__info-subtitle {
    font-size: 3rem;
  }

  .section {
    padding: 2rem;
  }

  .section__title {
    font-size: 3rem;
    line-height: 150%;
    text-align: center;
  }

  .locations-container {
    width: 100%;
  }

  .requirements-container {
    width: 100%;
  }

  .footer__info {
    flex-direction: column;
    gap: 2rem;
  }

  .footer__legal {
    flex-direction: column;
    gap: 0.5rem;
  }

  .congress {
    padding: 1.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .congress__text {
    text-align: center;
  }

  .congress__container-img {
    display: none;
  }
}
